import React, { Fragment } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Box, Divider, Flex } from "@chakra-ui/react";
import styled from "styled-components";
import SEO from "../components/seo";
import background from "../assets/images/clouds.jpg";
import Nav from "../components/Nav";
//import XXX from "../assets/svg/finger.inline.svg";

const Container = styled.div`
	position: relative;
	max-width: 800px;
	margin: 80px auto;
`;

const Title = styled.h1`
	font-family: "Poppins";
	font-weight: 900;
	font-style: normal;
	font-size: 3.4rem;
	line-height: 0.94;
	color: #1a1818;
	padding-bottom: 1.6rem;
`;

const Ingress = styled.div`
	p {
		font-family: "Poppins";
		font-weight: 900;
		font-style: normal;
		font-size: 1.2rem;
		line-height: 1.04;
		color: #1a1818;
		padding-bottom: 2rem;
	}
`;
const Text = styled.p`
	font-family: "Poppins";
	font-weight: 300;
	font-style: normal;
	font-size: 1.1rem;
	line-height: 1.4;
`;

const CoopTitle = styled.h4`
	font-family: "Poppins";
	font-size: 24px;
	font-weight: 900;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: left;
	color: #523178;
`;

const CoopDesc = styled.h5`
	font-family: "Poppins";
	font-weight: 700;
	font-style: normal;
	line-height: 1.05;
	font-size: 2rem;
	color: #1a1818;
`;

const CTA = styled.div`
	font-family: "Poppins";
	font-size: 1.5rem;
	font-weight: 900;
	font-style: normal;
	line-height: 2.29;
	letter-spacing: 7px;
	text-align: center;
	color: #1a1818;

	a {
		text-decoration: underline;
	}
`;

const HankkeetTitle = styled.h4`
	font-family: "Poppins";
	font-size: 1.5rem;
	font-weight: 900;
	font-style: normal;
	line-height: 2.29;
	letter-spacing: 7px;
	color: #1a1818;
`;

const HankeRichText = styled.div`
	font-family: "Poppins";
	font-weight: 300;
	p {
		margin-top: 1.6rem;
	}
	h4 {
		font-size: 2rem;
	}
	strong {
		font-weight: 900;
	}
`;

export default function YhteistyoPage({ data: { page } }) {
	return (
		<>
<SEO title={page.seo.title} description={page.seo.metaDesc} />
<Nav />
		<Box pos="relative">
			<Box
				display={["none","block"]}
				pos="absolute"
				top={0}
				left={"-170px"}
				width={615}
				height={420}
				transform="rotate(90deg)"
				background={`url(${background})`}
			/>
			<Container>
				<Flex direction={["column", "row"]}>
					<Box w={["100%", "50%"]} pl={"16px"} pr={["16px", "50px"]}>
						<Title>{page.frontpageLinkTitle.styledTitle}</Title>
						<Ingress
							dangerouslySetInnerHTML={{
								__html: page.content,
							}}
						/>
					</Box>
					<Box w={["100%", "50%"]} pr={"16px"} pl={["16px", "50px"]}>
						<Text>{page.yhteistyoData.yhteistyoParagraph}</Text>
					</Box>
				</Flex>

				<Box>
					{page.yhteistyoData.yhteistyotRepeater.map((item, i) => (
						<Fragment key={`coop-${i}`}>
							<Divider
								borderBottomWidth={"3px"}
								mt={"40px"}
								mb={"40px"}
								borderColor="#00358e"
							/>
							<Box pl={"16px"}>
								<CoopTitle>{i + 1}. Yhteistyö</CoopTitle>
							</Box>
							<Flex direction={["column", "row"]} align="center">
								<Box
									w={["100%", "50%"]}
									pl={"16px"}
									pr={["16px", "50px"]}
								>
									<GatsbyImage
										image={
											item.yhteistyoLogo.localFile
												.childImageSharp.gatsbyImageData
										}
										style={{
											maxWidth: "400px",
											maxHeight: "100%",
										}}
										imgStyle={{
											objectFit: "contain",
										}}
									/>
								</Box>
								<Box
									w={["100%", "50%"]}
									pr={"16px"}
									pl={["16px", "50px"]}
								>
									<Text>Yhteistyön laajuus:</Text>
									<CoopDesc>{item.yhteistyoText}</CoopDesc>
								</Box>
							</Flex>

							<Divider
								borderBottomWidth={"3px"}
								mt={"80px"}
								mb={"40px"}
								borderColor="#00358e"
							/>
						</Fragment>
					))}
				</Box>

				<Box pl={"16px"}>
					<HankkeetTitle>TULEVAT HANKKEET</HankkeetTitle>
					{page.yhteistyoData.hankkeetRepeater.map((item, i) => (
						<Box key={`hankkeet-${i}`} my={"20px"}>
							<HankeRichText
								dangerouslySetInnerHTML={{
									__html: item.hankeText,
								}}
							/>
						</Box>
					))}
				</Box>
				<Box bg="#ffc600" mt={130} mx={"16px"} px={[10, 100]} py={36}>
					<CTA
						dangerouslySetInnerHTML={{
							__html: page.yhteistyoData.hankkeetCta,
						}}
					/>
				</Box>
			</Container>
		</Box>
		</>
	);
}

export const query = graphql`
	fragment YhteistyoImage on File {
		childImageSharp {
			fluid(maxWidth: 400) {
				...GatsbyImageSharpFluid
			}
		}
	}
	query {
		page: wpPage(slug: { eq: "yhteistyoosio" }) {
			content
			title
			seo {
				metaDesc
				title
			}
			yhteistyoData {
				hankkeetCta
				hankkeetRepeater {
					hankeText
				}
				yhteistyoParagraph
				yhteistyotRepeater {
					yhteistyoText
					yhteistyoLogo {
						localFile {
							childImageSharp { 
								gatsbyImageData(width: 400)
							}
						}
					}
				}
			}
			frontpageLinkTitle {
				styledTitle
			}
		}
	}
`;
